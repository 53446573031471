import React from 'react';
import Card from './Card';
import './Waste.css';

const Waste = ({ waste, onCardClick }) => {
    return (
        <div className="waste">
            {waste.length > 0 ? (
                <Card
                    card={waste[waste.length - 1]}
                    onCardClick={onCardClick}
                    column={waste}
                    index={waste.length - 1}
                    style={{ position: 'relative', top: 0, left: 0 }}
                />
            ) : (
                <div className="empty">Empty</div>
            )}
        </div>
    );
};

export default Waste;