// src/components/Timer.js
import React from 'react';
import './Timer.css';

const Timer = ({ seconds, moves, stockWasteCount, tableauCount, faceDownCount }) => {
    const formatTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const remainingSeconds = secs % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className="timer">
            <div>Time: {formatTime(seconds)}</div>
            <div>Moves: {moves}</div>
        </div>
    );
};

export default Timer;