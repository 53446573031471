import React, { useEffect, useState } from 'react';
import './WinOverlay.css';

const WinOverlay = ({ hasWon, time, moves, onRestart }) => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [currentEntry, setCurrentEntry] = useState(null);

    useEffect(() => {
        if (hasWon) {
            const savedLeaderboard = JSON.parse(localStorage.getItem('leaderboard')) || [];
            const newEntry = { time, moves };
            setCurrentEntry(newEntry);
            let updatedLeaderboard = [...savedLeaderboard, newEntry].sort((a, b) => (a.time + a.moves) - (b.time + b.moves));
            updatedLeaderboard = updatedLeaderboard.slice(0, 10);
            localStorage.setItem('leaderboard', JSON.stringify(updatedLeaderboard));
            setLeaderboard(updatedLeaderboard);
        }
    }, [hasWon, time, moves]);

    if (!hasWon) return null;

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="win-overlay">
            <div className="win-message">
                <h2>Congratulations! You won the game!</h2>
                <p>Your time: {formatTime(time)}</p>
                <p>Your moves: {moves}</p>
                <button onClick={onRestart}>Restart Game</button>
                <div className="leaderboard">
                    <h3>Leaderboard</h3>
                    <table>
                        <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Time</th>
                            <th>Moves</th>
                            <th>Total Score</th>
                        </tr>
                        </thead>
                        <tbody>
                        {leaderboard.map((entry, index) => (
                            <tr key={index} className={entry === currentEntry ? 'highlight' : ''}>
                                <td>{index + 1}</td>
                                <td>{formatTime(entry.time)}</td>
                                <td>{entry.moves}</td>
                                <td>{entry.time + entry.moves}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default WinOverlay;