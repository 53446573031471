// src/components/Column.js
import React from 'react';
import { useDrop } from 'react-dnd';
import Card from './Card';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './Column.css';

const Column = ({ column, columnIndex, onCardDrop, onCardClick }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'CARD',
        drop: (item) => onCardDrop(item.cards, columnIndex),
        canDrop: (item) => {
            const targetCard = column[column.length - 1];
            const sourceCard = item.cards[0];
            if (!targetCard) {
                return sourceCard.value === 'K';
            }
            const sourceValue = getCardValue(sourceCard.value);
            const targetValue = getCardValue(targetCard.value);
            const isOppositeColor = (sourceCard.suit === 'hearts' || sourceCard.suit === 'diamonds') ?
                (targetCard.suit === 'clubs' || targetCard.suit === 'spades') :
                (targetCard.suit === 'hearts' || targetCard.suit === 'diamonds');
            return isOppositeColor && targetValue === sourceValue + 1;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const getCardValue = (value) => {
        const values = { 'A': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9, '10': 10, 'J': 11, 'Q': 12, 'K': 13 };
        return values[value];
    };

    return (
        <div ref={drop} className={`column ${isOver ? 'hover' : ''} ${canDrop ? 'can-drop' : ''}`}>
            <TransitionGroup component={null}>
                {column.map((card, index) => (
                    <CSSTransition
                        key={index}
                        timeout={300}
                        classNames="card-transition"
                    >
                        <Card
                            card={card}
                            onCardClick={onCardClick}
                            column={column}
                            index={index}
                            style={{ top: `${index * 25}px` }} // Adjust the 25px value as needed for spacing
                        />
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    );
};

export default Column;