// src/components/Card.js

import React from 'react';
import { useDrag } from 'react-dnd';
import './Card.css';

const Card = ({ card, onCardClick, column, index, style }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'CARD',
        item: { cards: column ? column.slice(index) : [card] },
        canDrag: () => card.isFaceUp,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [card, column, index]);

    const valueMap = {
        'A': 'Ace',
        'J': 'Jack',
        'Q': 'Queen',
        'K': 'King'
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const cardValue = valueMap[card.value] || card.value;
    const cardSuit = capitalizeFirstLetter(card.suit); // Capitalize the first letter of card.suit
    const cardName = `Suit=${cardSuit}, Number=${cardValue}.png`;
    const cardImage = `./components/img/${cardName}`;
    const backImage = './components/img/Suit=Other, Number=Back Red.png';

    return (
        <div
            ref={drag}
            className={`card ${card.suit} ${card.isFaceUp ? 'face-up' : 'face-down'} ${isDragging ? 'dragging' : ''}`}
            onClick={() => onCardClick && card.isFaceUp && onCardClick(card)}
            style={style}
        >
            {card.isFaceUp ? (
                <img src={cardImage} alt={`${card.value} of ${card.suit}`} className="card-image" />
            ) : (
                <img src={backImage} alt="Face Down" className="card-image" />
            )}
        </div>
    );
};

export default Card;