import React, { useState, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Deck from './components/Deck';
import Tableau from './components/Tableau';
import Foundation from './components/Foundation';
import Stock from './components/Stock';
import Waste from './components/Waste';
import Timer from './components/Timer'; // Import Timer component
import WinOverlay from './components/WinOverlay'; // Import WinOverlay component
import './App.css';

const App = () => {
    const [tableau, setTableau] = useState([[], [], [], [], [], [], []]);
    const [stock, setStock] = useState([]);
    const [waste, setWaste] = useState([]);
    const [foundations, setFoundations] = useState([[], [], [], []]); // 4 foundation piles
    const [history, setHistory] = useState([]); // History state
    const [seconds, setSeconds] = useState(0); // Timer state
    const [hasWon, setHasWon] = useState(false);
    const [hasStarted, setHasStarted] = useState(false); // To handle the initial start of the game
    const timerInterval = useRef(null);
    const [isTimerStarted, setIsTimerStarted] = useState(false);
    const [points, setPoints] = useState(0); // Points state
    const [moves, setMoves] = useState(0); // Moves state
    const [lastMoveTime, setLastMoveTime] = useState(0); // Track the last move time

    useEffect(() => {
        if (!isTimerStarted) return;
        return () => clearInterval(timerInterval.current);
    }, [isTimerStarted]);

    useEffect(() => {
        const deck = generateDeck();
        dealCards(deck);
    }, []);

    useEffect(() => {
        const startGame = setTimeout(() => {
            setHasStarted(true);
        }, 5000); // 5 seconds delay before starting the game

        return () => clearTimeout(startGame);
    }, []);

    useEffect(() => {
        if (hasStarted) {
            checkForWin();
        }
    }, [tableau, hasStarted]);

    const calculateScore = (moves, time) => {
        const basePoints = 1000;
        const movePenalty = moves * 5;
        let timeBonus = 0;

        if (time <= 600) {
            timeBonus = (600 - time) * 2;
        } else {
            timeBonus = (600 - time) * 1;
        }

        const finalScore = basePoints - movePenalty + timeBonus;
        return finalScore;
    };

    const generateDeck = () => {
        const suits = ['hearts', 'diamonds', 'clubs', 'spades'];
        const values = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
        let deck = [];
        for (let suit of suits) {
            for (let value of values) {
                deck.push({ value, suit, isFaceUp: false });
            }
        }
        return shuffle(deck);
    };

    const shuffle = (deck) => {
        for (let i = deck.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [deck[i], deck[j]] = [deck[j], deck[i]];
        }
        return deck;
    };

    const observer = () => {
        const allCards = [...stock, ...waste, ...foundations.flat(), ...tableau.flat()];
        const cardCount = {};

        allCards.forEach(card => {
            const cardKey = `${card.suit}-${card.value}`;
            if (cardCount[cardKey]) {
                cardCount[cardKey]++;
            } else {
                cardCount[cardKey] = 1;
            }
        });

        const duplicates = Object.entries(cardCount).filter(([key, count]) => count > 1);
        if (duplicates.length > 0) {
            console.warn('Duplicate cards found:', duplicates);
            return false;
        }
        return true;
    };

    const recycleWasteToStock = () => {
        if (stock.length === 0 && waste.length > 0) {
            saveCurrentStateToHistory();

            // Create a Set to track unique cards
            const seen = new Set();
            const newStock = waste.slice().reverse().filter(card => {
                const cardString = JSON.stringify(card);
                if (seen.has(cardString)) {
                    return false;
                }
                seen.add(cardString);
                return true;
            }).map(card => ({ ...card, isFaceUp: false }));

            setStock(newStock);
            setWaste([]);
        }
    };

    const checkForWin = () => {
        const allFaceUp = tableau.every(column => column.every(card => card.isFaceUp));
        if (allFaceUp) {
            setHasWon(true);
            clearInterval(timerInterval.current); // Stop the timer when the game is won
        }
    };

    const handleRestart = () => {
        window.location.reload();
    };

    const incrementMoves = () => {
        const now = Date.now();
        if (now - lastMoveTime >= 250) { // Ensure at least one second has passed
            setMoves(prevMoves => {
                setLastMoveTime(now);
                return prevMoves + 1;
            });
        }
    };

    const drawFromStock = () => {
        if (stock.length > 0) {
            saveCurrentStateToHistory();
            setStock(prevStock => {
                const newStock = [...prevStock];
                const card = newStock.pop();
                card.isFaceUp = true;
                setWaste(prevWaste => [...prevWaste, card]);
                return newStock;
            });
        } else {
            recycleWasteToStock();
        }

        setPoints(prevPoints => prevPoints - 1); // Subtract points for drawing from stock
        incrementMoves(); // Use incrementMoves instead of setMoves
    };

    const getUniqueCardCount = (stock, waste) => {
        const uniqueCards = new Set();
        [...stock, ...waste].forEach(card => {
            uniqueCards.add(`${card.suit}-${card.value}`);
        });
        return uniqueCards.size;
    };

    const saveCurrentStateToHistory = () => {
        setHistory(prevHistory => [
            ...prevHistory,
            { tableau: JSON.parse(JSON.stringify(tableau)), waste: JSON.parse(JSON.stringify(waste)), stock: JSON.parse(JSON.stringify(stock)), foundations: JSON.parse(JSON.stringify(foundations)) }
        ]);
    };

    const dealCards = (deck) => {
        let newTableau = [[], [], [], [], [], [], []];
        for (let i = 0; i < 7; i++) {
            for (let j = 0; j <= i; j++) {
                const card = deck.pop();
                if (j === i) {
                    card.isFaceUp = true;
                }
                newTableau[i].push(card);
            }
        }
        setTableau(newTableau);
        setStock(deck);
    };

    const moveToFoundation = (card, sourceColumnIndex, foundationIndex) => {
        let shouldCountMove = false; // Track if a move should be counted

        saveCurrentStateToHistory(); // Save the current state before making the move

        setTableau(prevTableau => {
            const newTableau = prevTableau.map((column, columnIndex) => {
                if (columnIndex === sourceColumnIndex) {
                    const filteredColumn = column.filter(c => c !== card);
                    if (filteredColumn.length > 0) {
                        filteredColumn[filteredColumn.length - 1].isFaceUp = true;
                        setPoints(prevPoints => prevPoints + 5); // Add points for flipping a card
                    }
                    shouldCountMove = true; // Mark that a move should be counted
                    return filteredColumn;
                }
                return column;
            });
            return newTableau;
        });

        setFoundations(prevFoundations => {
            const newFoundations = prevFoundations.map((foundation, index) => {
                if (index === foundationIndex) {
                    return [...foundation, card];
                }
                return foundation;
            });
            return newFoundations;
        });

        setPoints(prevPoints => prevPoints + 10); // Add points for moving to foundation

        if (shouldCountMove) {
            incrementMoves(); // Ensure this is only called once
        }

        checkForWin(); // Check if the game is won
    };

    const moveToTableauFromFoundation = (card, foundationIndex, targetColumnIndex) => {
        setFoundations(prevFoundations => {
            const newFoundations = prevFoundations.map((foundation, index) => {
                if (index === foundationIndex) {
                    return foundation.filter(c => c !== card);
                }
                return foundation;
            });

            observer(); // Call observer function
            return newFoundations;
        });

        setTableau(prevTableau => {
            const newTableau = prevTableau.map((column, columnIndex) => {
                if (columnIndex === targetColumnIndex) {
                    return [...column, card];
                }
                return column;
            });

            observer(); // Call observer function
            return newTableau;
        });

        incrementMoves(); // Use incrementMoves instead of setMoves
    };

    const moveToColumn = (card, sourceColumnIndex, targetColumnIndex) => {
        let shouldCountMove = false; // Track if a move should be counted

        setTableau(prevTableau => {
            const newTableau = prevTableau.map((column, columnIndex) => {
                if (columnIndex === sourceColumnIndex) {
                    const filteredColumn = column.filter(c => c !== card);
                    if (filteredColumn.length > 0) {
                        filteredColumn[filteredColumn.length - 1].isFaceUp = true;
                        setPoints(prevPoints => prevPoints + 5); // Add points for flipping a card
                    }
                    shouldCountMove = true; // Mark that a move should be counted
                    return filteredColumn;
                }
                if (columnIndex === targetColumnIndex) {
                    return [...column, card];
                }
                return column;
            });
            return newTableau;
        });

        setPoints(prevPoints => prevPoints + 1); // Add points for moving to column

        if (shouldCountMove) {
            incrementMoves(); // Ensure this is only called once
        }

        checkForWin(); // Add this line to check for win condition
    };

    const moveToColumnFromWaste = (card, targetColumnIndex) => {
        let shouldCountMove = false; // Track if a move should be counted

        setWaste(prevWaste => prevWaste.filter(w => w !== card));

        setTableau(prevTableau => {
            const newTableau = prevTableau.map((column, columnIndex) => {
                if (columnIndex === targetColumnIndex) {
                    shouldCountMove = true; // Mark that a move should be counted
                    return [...column, card];
                }
                return column;
            });
            return newTableau;
        });

        setPoints(prevPoints => prevPoints + 1); // Add points for moving to column

        if (shouldCountMove) {
            incrementMoves(); // Ensure this is only called once
        }

        checkForWin(); // Add this line to check for win condition
    };

    const moveToFoundationFromWaste = (card, foundationIndex) => {
        let shouldCountMove = false; // Track if a move should be counted

        saveCurrentStateToHistory();

        setFoundations(prevFoundations => {
            const newFoundations = prevFoundations.map((foundation, index) => {
                if (index === foundationIndex) {
                    shouldCountMove = true; // Mark that a move should be counted
                    return [...foundation, card];
                }
                return foundation;
            });
            return newFoundations;
        });

        setWaste(prevWaste => prevWaste.filter(w => w !== card));

        setPoints(prevPoints => prevPoints + 10); // Add points for moving to foundation

        if (shouldCountMove) {
            incrementMoves(); // Ensure this is only called once
        }

        checkForWin(); // Check if the game is won
    };

    const handleCardDrop = (cards, targetColumnIndex) => {
        const sourceColumnIndex = tableau.findIndex(column => column.includes(cards[0]));
        const cardFromWaste = waste.find(w => w === cards[0]);
        const foundationIndex = foundations.findIndex(foundation => foundation.includes(cards[0]));

        let shouldCountMove = false; // Track if a move should be counted

        if (sourceColumnIndex !== -1 || cardFromWaste || foundationIndex !== -1) {
            const targetColumn = tableau[targetColumnIndex];
            const targetCard = targetColumn[targetColumn.length - 1];

            // Check if the move is valid according to Solitaire rules
            if (isValidMove(cards[0], targetCard)) {
                saveCurrentStateToHistory();

                if (sourceColumnIndex !== -1) {
                    setTableau(prevTableau => {
                        const newTableau = prevTableau.map((column, columnIndex) => {
                            if (columnIndex === sourceColumnIndex) {
                                const filteredColumn = column.filter(c => !cards.includes(c));
                                if (filteredColumn.length > 0) {
                                    filteredColumn[filteredColumn.length - 1].isFaceUp = true;
                                }
                                shouldCountMove = true; // Mark that a move should be counted
                                return filteredColumn;
                            }
                            if (columnIndex === targetColumnIndex) {
                                return [...column, ...cards];
                            }
                            return column;
                        });

                        observer(); // Call observer function
                        return newTableau;
                    });
                } else if (cardFromWaste) {
                    setWaste(prevWaste => prevWaste.filter(w => w !== cardFromWaste));
                    setTableau(prevTableau => {
                        const newTableau = prevTableau.map((column, columnIndex) => {
                            if (columnIndex === targetColumnIndex) {
                                shouldCountMove = true; // Mark that a move should be counted
                                return [...column, ...cards];
                            }
                            return column;
                        });

                        observer(); // Call observer function
                        return newTableau;
                    });
                } else if (foundationIndex !== -1) {
                    moveToTableauFromFoundation(cards[0], foundationIndex, targetColumnIndex);
                    shouldCountMove = true; // Mark that a move should be counted
                }

                if (shouldCountMove) {
                    incrementMoves(); // Ensure this is only called once
                }

                checkForWin(); // Add this line to check for win condition
            }
        }
    };

    const handleCardClick = (card) => {
        // Start the timer if it hasn't started yet
        if (!isTimerStarted) {
            setIsTimerStarted(true);
            timerInterval.current = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);
        }

        const sourceColumnIndex = tableau.findIndex(column => column.includes(card));
        const foundationIndex = foundations.findIndex(foundation => foundation.includes(card));

        let shouldCountMove = false; // Track if a move should be counted

        if (sourceColumnIndex !== -1) {
            const cardIndex = tableau[sourceColumnIndex].indexOf(card);
            const cardsToMove = tableau[sourceColumnIndex].slice(cardIndex);

            // Check if the card is the last card in the column
            const isLastCard = cardIndex === tableau[sourceColumnIndex].length - 1;

            if (isLastCard) {
                const foundationIndex = getFoundationIndex(card.suit);
                const targetFoundation = foundations[foundationIndex];

                if (isValidMoveToFoundation(card, targetFoundation)) {
                    saveCurrentStateToHistory();
                    moveToFoundation(card, sourceColumnIndex, foundationIndex);
                    shouldCountMove = true; // Mark that a move should be counted
                    return; // Ensure to return to avoid further state updates
                }
            }

            // Try moving to tableau
            for (let i = 0; i < tableau.length; i++) {
                if (i !== sourceColumnIndex) {
                    const targetColumn = tableau[i];
                    const targetCard = targetColumn[targetColumn.length - 1];
                    if (isValidMove(card, targetCard)) {
                        saveCurrentStateToHistory();
                        setTableau(prevTableau => {
                            const newTableau = prevTableau.map((column, columnIndex) => {
                                if (columnIndex === sourceColumnIndex) {
                                    const filteredColumn = column.filter(c => !cardsToMove.includes(c));
                                    if (filteredColumn.length > 0) {
                                        filteredColumn[filteredColumn.length - 1].isFaceUp = true;
                                        setPoints(prevPoints => prevPoints + 5); // Add points for flipping a card
                                    }
                                    return filteredColumn;
                                }
                                if (columnIndex === i) {
                                    return [...column, ...cardsToMove];
                                }
                                return column;
                            });
                            return newTableau;
                        });
                        setPoints(prevPoints => prevPoints + 1); // Add points for moving to column
                        shouldCountMove = true; // Mark that a move should be counted
                        break; // Exit the loop once the move is made
                    }
                }
            }
        } else if (foundationIndex !== -1) {
            // Handle card click from foundation
            for (let i = 0; i < tableau.length; i++) {
                const targetColumn = tableau[i];
                const targetCard = targetColumn[targetColumn.length - 1];
                if (!targetCard || isValidMove(card, targetCard)) {
                    saveCurrentStateToHistory();
                    moveToTableauFromFoundation(card, foundationIndex, i);
                    setPoints(prevPoints => prevPoints + 1); // Add points for moving to column
                    shouldCountMove = true; // Mark that a move should be counted
                    break; // Exit the loop once the move is made
                }
            }
        } else {
            // Handle card click from waste pile
            const foundationIndex = getFoundationIndex(card.suit);
            const targetFoundation = foundations[foundationIndex];

            if (isValidMoveToFoundation(card, targetFoundation)) {
                saveCurrentStateToHistory();
                moveToFoundationFromWaste(card, foundationIndex);
                shouldCountMove = true; // Mark that a move should be counted
            } else {
                // Try moving to tableau from waste
                for (let i = 0; i < tableau.length; i++) {
                    const targetColumn = tableau[i];
                    const targetCard = targetColumn[targetColumn.length - 1];
                    if (isValidMove(card, targetCard)) {
                        saveCurrentStateToHistory();
                        moveToColumnFromWaste(card, i);
                        shouldCountMove = true; // Mark that a move should be counted
                        break; // Exit the loop once the move is made
                    }
                }
            }
        }

        if (shouldCountMove) {
            incrementMoves(); // Ensure this is only called once
        }

        checkForWin(); // Check if the game is won
    };

    const isValidMove = (card, targetCard) => {
        if (!targetCard) {
            return card.value === 'K'; // Only Kings can be placed on empty columns
        }
        const cardValue = getCardValue(card.value);
        const targetCardValue = getCardValue(targetCard.value);
        const isOppositeColor = (card.suit === 'hearts' || card.suit === 'diamonds') ?
            (targetCard.suit === 'clubs' || targetCard.suit === 'spades') :
            (targetCard.suit === 'hearts' || targetCard.suit === 'diamonds');
        return isOppositeColor && targetCardValue === cardValue + 1;
    };

    const isValidMoveToFoundation = (card, foundation) => {
        if (foundation.length === 0) {
            return card.value === 'A';
        }
        const topCard = foundation[foundation.length - 1];
        return getCardValue(topCard.value) + 1 === getCardValue(card.value) && topCard.suit === card.suit;
    };

    const getCardValue = (value) => {
        const values = { 'A': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9, '10': 10, 'J': 11, 'Q': 12, 'K': 13 };
        return values[value];
    };

    const getFoundationIndex = (suit) => {
        const suits = ['hearts', 'diamonds', 'clubs', 'spades'];
        return suits.indexOf(suit);
    };

    const handleUndo = () => {
        setHistory(prevHistory => {
            if (prevHistory.length > 0) {
                const lastState = prevHistory[prevHistory.length - 1];
                setTableau(lastState.tableau);
                setWaste(lastState.waste);
                setStock(lastState.stock);
                setFoundations(lastState.foundations);

                observer(); // Call observer function
                return prevHistory.slice(0, -1); // Remove the last state from history
            }
            return prevHistory;
        });
    };

    const triggerWin = () => {
        clearInterval(timerInterval.current); // Stop the timer
        const finalScore = calculateScore(moves, seconds); // Ensure the final score is calculated
        setPoints(finalScore);
        setHasWon(true);
        console.log(`Win triggered manually. Moves: ${moves}, Time: ${seconds}, Points: ${finalScore}`);
    };

    // Make the function accessible from the console
    window.triggerWin = triggerWin;

    const logState = () => {
        console.log(`Current state - Moves: ${moves}, Time: ${seconds}, Points: ${points}`);
    };

    // Make the function accessible from the console
    window.logState = logState;

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="App">
                <div className="navigation">
                    <button className="undo-button" onClick={handleUndo}>Undo</button>
                    <button className="new-game-button" onClick={handleRestart}>New Game</button>
                </div>
                <div className="top-section">
                    <div className="stock-waste">
                        <Stock stock={stock} drawFromStock={drawFromStock} />
                        <Waste waste={waste} onCardClick={handleCardClick} />
                    </div>
                    <Foundation foundations={foundations} onCardClick={handleCardClick} />
                </div>
                <Tableau tableau={tableau} onCardDrop={handleCardDrop} onCardClick={handleCardClick} />
                <Timer
                    seconds={seconds}
                    moves={moves} // Pass moves instead of points
                />
                {hasWon && <WinOverlay hasWon={hasWon} time={seconds} points={points} moves={moves} onRestart={handleRestart} />}
            </div>
        </DndProvider>
    );
};

export default App;