import React from 'react';
import Card from './Card';
import './Foundation.css';

const Foundation = ({ foundations, onCardClick }) => {
    return (
        <div className="foundation">
            {foundations.map((foundation, index) => (
                <div key={index} className="foundation-pile">
                    {foundation.length > 0 && (
                        <Card
                            card={foundation[foundation.length - 1]}
                            onCardClick={onCardClick}
                            column={foundation}
                            index={foundation.length - 1}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default Foundation;