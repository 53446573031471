// src/components/Stock.js
import React from 'react';
import './Stock.css';

const Stock = ({ stock, drawFromStock }) => {
    const backImage = `${process.env.PUBLIC_URL}/components/img/Suit=Other, Number=Back Red.png`;

    return (
        <div className="stock" onClick={drawFromStock}>
            {stock.length > 0 ? (
                <img src={backImage} alt="Stockpile" className="stock-image" />
            ) : (
                <div className="empty"></div>
            )}
        </div>
    );
};

export default Stock;