// src/components/Tableau.js
import React from 'react';
import Column from './Column';
import './Tableau.css';

const Tableau = ({ tableau, onCardDrop, onCardClick }) => {
    return (
        <div className="tableau">
            {tableau.map((column, columnIndex) => (
                <Column
                    key={columnIndex}
                    column={column}
                    columnIndex={columnIndex}
                    onCardDrop={onCardDrop}
                    onCardClick={onCardClick}
                />
            ))}
        </div>
    );
};

export default Tableau;
